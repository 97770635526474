import { windowWidth } from "@styles/global";
import Login from "@assets/Backgrounds/Login.svg";
import LoginMobile from "@assets/Backgrounds/LoginMobile.svg";
import styled from "styled-components";

const Container = styled.div`
  background-image: url(${Login});
  background-size: cover;
  background-position: center;
  height: 100%;
  display: flex;

  @media (max-width: ${windowWidth.tablet}) {
    background-image: url(${LoginMobile});
  }
`;

const Panel = styled.div`
  display: flex;
  margin: auto;
  min-height: 400px;
  padding: 20px;
  background-color: #fff;
  border-radius: 10px;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;

  h1 {
    margin: 10px 0px;
  }
`;

const Button = styled.button`
  background-color: rgb(0, 41, 81);
  border: none;
  border-radius: 50px;
  cursor: pointer;
  display: inline-block;
  font-size: 1.5rem;
  font-weight: 500;
  min-height: 50px;
  line-height: 1.3333333;
  padding: 10px 24px;
  text-align: center;
  color: #fff;
  box-shadow: 0 0 30px 0 #dae1f7;
  text-transform: uppercase;
`;

const ProductContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  padding: 30px 0px;
`;

const ProductLine = styled.div`
  width: 100%;
  padding: 10px 0px;

  img {
    height: 50px;
  }
`;

export {
  Container,
  Panel,
  Button,
  ProductContainer,
  ProductLine
};
