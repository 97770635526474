import {
  ConfirmAccountMessages,
  TranslationConfirmAccountMessages
} from "@languages/interfaces/confirmAccountMessages";

// eslint-disable-next-line import/prefer-default-export
export const messages: TranslationConfirmAccountMessages = {
  [ConfirmAccountMessages.confirmAccountLabel]: "Senha",
  [ConfirmAccountMessages.confirmAccountRequired]: "A senha é obrigatória",
  [ConfirmAccountMessages.confirmAccountButton]: "Confirma nova senha",
  [ConfirmAccountMessages.confirmAccountGoToLoginPageButton]: "Ir para a página de login",
  [ConfirmAccountMessages.confirmAccountFailed]: "Falha ao confirmar nova senha",
  [ConfirmAccountMessages.confirmAccountInfo]: "Por favor, confirme sua conta para restaurar sua senha",
  [ConfirmAccountMessages.confirmAccountMessage]: "Conta confirmada com sucesso!<br><br>"
  + "Clique no botão abaixo para realizar seu primeiro acesso!<br>"
  + "Bem-vindo ao Topcon Suite"
};
