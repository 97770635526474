import {
  ConfirmAccountMessages,
  TranslationConfirmAccountMessages
} from "@languages/interfaces/confirmAccountMessages";

// eslint-disable-next-line import/prefer-default-export
export const messages: TranslationConfirmAccountMessages = {
  [ConfirmAccountMessages.confirmAccountLabel]: "Contraseña",
  [ConfirmAccountMessages.confirmAccountRequired]: "La contraseña es requerida",
  [ConfirmAccountMessages.confirmAccountButton]: "Confirmar nueva contraseña",
  [ConfirmAccountMessages.confirmAccountGoToLoginPageButton]: "Ir a la página de inicio de sesión",
  [ConfirmAccountMessages.confirmAccountFailed]: "No se pudo confirmar la nueva contraseña",
  [ConfirmAccountMessages.confirmAccountInfo]: "Por favor, confirme su cuenta para restablecer su"
  + " contraseña",
  [ConfirmAccountMessages.confirmAccountMessage]: "¡Cuenta confirmada con éxito!<br><br>"
  + "Haga clic en el botón de abajo para iniciar sesión por primera vez!<br>"
  + "¡Bienvenido a Topcon Suite!"
};
