import { darkTheme } from "@styles/themes";
import styled from "styled-components";

const LogoContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;

  & > :first-child {
    width: 60%;
  }

  svg {
    width: 100%;

    * {
      fill: ${({ theme }) => theme === darkTheme && theme.colors.navBarIcons};
    }
  }
  
  .logoIconSVG {
    text-align: center;
    
    svg {
      width: 80px;
    }
  }
  .logoNameSVG {
    margin-top: 10px;
    
    svg {
      width: 250px;
    }
  }
`;

export default LogoContainer;
