import React, { useEffect } from "react";

// regioon Assets
import Dispatch from "@assets/TopconLogo/Dispatch_IconAndName.svg";
import Customer from "@assets/TopconLogo/Customer_IconAndName.svg";
import Tech from "@assets/TopconLogo/Tech_IconAndName.svg";
// endregion Assets
// region External Libraries
import { AuthenticatedTemplate, useMsal } from "@azure/msal-react";
import { EventType } from "@azure/msal-browser";
// endregion External Libraries
// region Hooks
import { b2cPolicies } from "src/authConfig";
// import { getDecodedToken } from "@hooks/useAuth";
// endregion Hooks
// region Store
import utils from "@store/helpers/utils";
// endregion Store
// region Styles
import * as Styled from "./styles";
// endregion Styles

const Login: React.FC = () => {

  const { instance } = useMsal();

  useEffect(() => {

    // Redirect to login page if user is not logged in
    if (!instance.getActiveAccount()) {
      setTimeout(() => { instance.loginRedirect().then(); }, 0);
    }

    /**
     * Silently acquires an access token which is then attached to a request for Microsoft Graph data.
     */
    const callbackId = instance.addEventCallback((event) => {

      if (
        (event.eventType === EventType.LOGIN_SUCCESS || event.eventType === EventType.ACQUIRE_TOKEN_SUCCESS)
        && (event.payload as any).account
      ) {

        // Set token in local storage
        localStorage.setItem("@Topcon:token", (event.payload as any)?.idToken || "");

        /**
         * For the purpose of setting an active account for UI update, we want to consider only the auth
         * response resulting from SUSI flow. "tfp" claim in the id token tells us the policy (NOTE: legacy
         * policies may use "acr" instead of "tfp"). To learn more about B2C tokens, visit:
         * https://docs.microsoft.com/en-us/azure/active-directory-b2c/tokens-overview
         */
        if ((event.eventType as any).idTokenClaims["tfp"] === b2cPolicies.names.editProfile) {

          // Retrieve the account from initial sing-in to the app
          const originalSignInAccount = instance
            .getAllAccounts()
            .find(
              (account) => account.idTokenClaims?.oid === (event.payload as any).idTokenClaims.oid
                && account.idTokenClaims?.sub === (event.payload as any).idTokenClaims.sub
                && account.idTokenClaims?.["tfp"] === b2cPolicies.names.signIn
            );

          const signInFlowRequest = {
            authority: b2cPolicies.authorities.signIn.authority,
            account: originalSignInAccount
          };

          // silently login again with the SignIn policy
          instance.ssoSilent(signInFlowRequest).then();
        }

        /**
         * Below we are checking if the user is returning from the reset password flow.
         * If so, we will ask the user to reauthenticate with their new password.
         * If you do not want this behavior and prefer your users to stay signed in instead,
         * you can replace the code below with the same pattern used for handling the return from
         * profile edit flow
         */
        if (utils.compareIssuingPolicy((event.payload as any).idTokenClaims, b2cPolicies.names.forgotPassword)) {

          const signInFlowRequest = {
            authority: b2cPolicies.authorities.signIn.authority,
            scopes: []
          };

          instance.loginRedirect(signInFlowRequest).then();
        }
      }

      if (event.eventType === EventType.LOGIN_FAILURE) {

        // Check for forgot password error
        // Learn more about AAD error codes at https://docs.microsoft.com/en-us/azure/active-directory/develop/reference-aadsts-error-codes
        if (event.error && (event.error as any).errorMessage.includes("AADB2C90118")) {

          const resetPasswordRequest = {
            authority: b2cPolicies.authorities.forgotPassword.authority,
            scopes: []
          };

          instance.loginRedirect(resetPasswordRequest).then();
        }
      }
    });

    return () => {

      if (callbackId) {
        instance.removeEventCallback(callbackId);
      }
    };

  }, [instance]);

  return (
    <AuthenticatedTemplate>
      <Styled.Container>
        { instance.getActiveAccount() ? (
          <Styled.Panel>
            <h1>Bem vindo!</h1>
            <p>{instance.getActiveAccount()?.name}</p>

            <Styled.ProductContainer>
              <Styled.ProductLine><a href="/dispatch"><img src={Dispatch} alt="Topcon" /></a></Styled.ProductLine>
              <Styled.ProductLine><a href="/customer"><img src={Customer} alt="Topcon" /></a></Styled.ProductLine>
              <Styled.ProductLine><a href="/tech"><img src={Tech} alt="Topcon" /></a></Styled.ProductLine>
            </Styled.ProductContainer>
            <Styled.Button type="button" onClick={() => instance.logout()}>Logout</Styled.Button>
          </Styled.Panel>
        ) : null}
      </Styled.Container>
    </AuthenticatedTemplate>
  );
};

export default Login;
