export enum ConfirmAccountMessages {
  confirmAccountLabel = "confirm.account.label",
  confirmAccountButton = "confirm.account.button",
  confirmAccountGoToLoginPageButton = "confirm.account.goToLoginPage.button",
  confirmAccountRequired = "confirm.account.required",
  confirmAccountFailed = "confirm.account.failed",
  confirmAccountInfo = "confirm.account.info",
  confirmAccountMessage = "confirm.account.message"
}

export interface TranslationConfirmAccountMessages {
  [ConfirmAccountMessages.confirmAccountLabel]: string;
  [ConfirmAccountMessages.confirmAccountButton]: string;
  [ConfirmAccountMessages.confirmAccountGoToLoginPageButton]: string;
  [ConfirmAccountMessages.confirmAccountRequired]: string;
  [ConfirmAccountMessages.confirmAccountFailed]: string;
  [ConfirmAccountMessages.confirmAccountInfo]: string;
  [ConfirmAccountMessages.confirmAccountMessage]: string;
}
