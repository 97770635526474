import React, { useCallback, useEffect, useState } from "react";
import ReactDOMServer from "react-dom/server";

// region imports - Services
import api from "@services/rest";
// endregion imports - Services
// region imports - store
import { ECommandResult } from "@store/enums/commandResult.enum";
// endregion imports - store
// region imports - Helpers
import utils from "@helpers/utils";
// endregion imports - Helpers
// region imports - Hooks
import { IToken, useAuth } from "@hooks/useAuth";
// endregion imports - Hooks
// region imports - Third-party libraries
import { useToast } from "@npm-telluria-tecnologia/telluria-ui";
import { Edit, LockReset } from "@libraries/mui/icons";
import * as _ from "lodash";
import * as dateFns from "date-fns";
// endregion imports - Third-party libraries
// region imports - Atoms
import { DataTableButton, Loading } from "@atoms/index";
// endregion imports - Atoms
// region imports - Molecules
import { DataTable, ModalFormDialog, UserForm } from "@molecules/index";
import { DataTableActions, DataTableButtons, DataTableColumns } from "@molecules/DataTable";
// endregion imports - Molecules
// region imports - Pages
import Office from "@pages/Office";
// endregion imports - Pages
// region imports - Languages
import useTranslation from "@languages/useTranslation";
import { ConfirmAccountMessages, GlobalMessages, MaintenanceUserMessages } from "@languages/interfaces";
// endregion imports - Languages
// region imports - Styles
import useTheme from "@styles/useTheme";
import * as Styled from "./styles";
// endregion imports - Styles

// region Types
export type UserType = {
  tenantId: string;
  id: string;
  module: string;
  fullName: string;
  email: string;
  active: boolean;
  createdAt: Date;
  updatedAt: Date;
};
export type TModeForm = "UPDATE" | "CREATE";
// endregion Types

const MaintenanceUser: React.FC = () => {

  // region Language
  const { t } = useTranslation();
  // endregion Language
  // region Hooks
  const { addToast } = useToast();
  const { theme } = useTheme();
  const { getToken, getDecodedToken } = useAuth();
  // endregion Hooks
  // region States
  const [users, setUsers] = useState<UserType[]>();
  const [userToEdit, setUserToEdit] = useState<UserType>();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [decodedToken, setDecodedToken] = useState<IToken>({} as IToken);
  // endregion States

  // region Form dialog user
  const [openFormDialogUser, setOpenFormDialogUser] = useState(false);
  const [isEditingAUser, setIsEditingAUser] = useState(false);
  // endregion Form dialog user

  // region Data table controls
  const [table, setTable] = useState<DataTables.Api<any>>({} as DataTables.Api<any>);
  const [tableClickedRow, setTableClickedRow] = useState<JQuery<HTMLTableRowElement>>({} as JQuery<HTMLTableRowElement>);

  const dataTableColumns: DataTableColumns[] = [
    // { // Tenant
    //   propertyName: "tenant",
    //   title: t(MaintenanceUserMessages.columnTenant),
    //   filterable: true,
    //   data: (user: UserType) => user.tenantId
    // },
    { // Full name
      propertyName: "fullName",
      title: t(MaintenanceUserMessages.columnFullName),
      filterable: true,
      data: (user: UserType) => user.fullName
    },
    { // Email
      propertyName: "email",
      title: t(MaintenanceUserMessages.columnEmail),
      filterable: true,
      data: (user: UserType) => user.email
    },
    { // Active
      propertyName: "active",
      className: "text-center",
      title: t(MaintenanceUserMessages.columnActive),
      filterable: true,
      data: (user: UserType) => (
        ReactDOMServer.renderToString(
          <b style={{ color: user.active ? theme.colors.success : theme.colors.error }}>
            {user.active ? t(GlobalMessages.activeText) : t(GlobalMessages.inactiveText)}
          </b>
        ))
    },
    { // Registration date
      propertyName: "createdAt",
      title: t(MaintenanceUserMessages.columnCreatedAt),
      filterable: true,
      data: (user: UserType) => user.createdAt,
      render: (data, type) => (type === "sort" ? data : (dateFns.format(new Date(data), "dd/MM/yyyy HH:mm")))
    },
    { // Update date
      propertyName: "updatedAt",
      title: t(MaintenanceUserMessages.columnUpdatedAt),
      filterable: true,
      data: (user: UserType) => user.updatedAt,
      render: (data, type) => (!data ? "" : (type === "sort" ? data : (dateFns.format(new Date(data), "dd/MM/yyyy HH:mm"))))
    },
    { // Actions
      propertyName: "",
      title: t(GlobalMessages.columnActions),
      filterable: false,
      orderable: false,
      searchable: false,
      data: (_user: UserType) => ReactDOMServer.renderToString(
        <Styled.Actions>
          <DataTableButton className="user-edit"><Edit /></DataTableButton>
          <DataTableButton className="user-update-password"><LockReset /></DataTableButton>
        </Styled.Actions>
      )
    }
  ];
  const dataTableSettings: DataTables.Settings = {
    order: [[0, "asc"]],
    columnDefs: [{ targets: -1, className: "dt-center" }]
  };
  const dataTableActions: DataTableActions[] = [
    {
      ref: ".user-edit",
      callback: (rowData: UserType) => {
        setUserToEdit(rowData);
        setIsEditingAUser(true);
        setOpenFormDialogUser(true);
      }
    },
    {
      ref: ".user-update-password",
      callback: (rowData: UserType) => {
        resetPasswordUser(rowData.email).then();
      }
    }
  ];
  const dataTableButtons: DataTableButtons[] = [
    {
      name: t(GlobalMessages.buttonAdd),
      key: "add",
      callback: () => handleOpenFormDialogUser(false)
    },
    {
      name: t(GlobalMessages.buttonRefresh),
      key: "refresh",
      callback: () => readUsers()
    }
  ];
  // endregion Data table controls

  // region User form dialog handlers
  const handleOpenFormDialogUser = (editing: boolean) => {

    setIsEditingAUser(editing);
    setOpenFormDialogUser(true);
  };
  const handleCloseFormDialogUser = () => setOpenFormDialogUser(false);
  // endregion User form dialog handlers
  // region User form events
  const handleSaveUserForm = (newUser: UserType, mode: TModeForm) => {

    if (mode === "CREATE") {

      setIsLoading(true);

      // Add inserted user in the table
      setUsers([...users || [], newUser]);
      table.row.add(newUser).draw();

      setIsLoading(false);

    } else if (mode === "UPDATE") {

      const mergeUser = _.assign(_.cloneDeep(userToEdit), newUser);

      // Update row in table with updated user data
      table.row(tableClickedRow).data(mergeUser);
      table.draw();
    }

    handleCloseFormDialogUser();
  };
  // endregion User form events

  // region Functions

  /**
   * Get user
   */
  const readUsers = useCallback(async () => {
    try {
      setIsLoading(true);

      const token = await getToken();
      const response = await api.get("/user/getall", {
        headers: {
          "Authorization": `Bearer ${token}`
        }
      });
      const responseData = response.data;

      if (responseData.status === ECommandResult.SUCCESS) {
        setUsers(responseData.result.users as UserType[]);
      }

    } catch (error) {
      addToast({ type: "error", title: "Internal client error", description: error.message, overwrite: true });
      setUsers([] as UserType[]);
    } finally {
      setIsLoading(false);
    }

  }, [addToast, table, getToken]);

  /**
   * Send the forgot password email
   * @param email The email to send the forgot password email
   */
  const resetPasswordUser = useCallback(async (email: string) => {

    setIsLoading(true);

    try {

      const domain = utils.toPascalCase(window.location.hostname.split(".")[0]);
      const response = await api.post("/auth/resendemailconfirmation", { email, domain }) as any;
      const responseData = response.data;
      const statusToast = utils.getToastStatusType(responseData.status);

      addToast({ type: statusToast, title: responseData.message, overwrite: true });

    } catch (error) {
      addToast({ type: "info", title: t(ConfirmAccountMessages.confirmAccountFailed), overwrite: true });
    } finally {
      setIsLoading(false);
    }

  }, [addToast]);

  // endregion Functions

  // region Effects
  useEffect(() => {
    getDecodedToken().then((decodedToken) => {
      setDecodedToken(decodedToken as IToken);
    });
  }, []);

  // Mount components
  useEffect(() => {

    readUsers().then();

    return () => { setUsers([]); };

  }, []);
  // endregion Effects

  return (
    <Office>
      <Styled.Container>
        <Loading loading={isLoading} />
        {users && (
          <DataTable
            title={t(GlobalMessages.entityUser)}
            data={users}
            columns={dataTableColumns}
            settings={dataTableSettings}
            actions={dataTableActions}
            buttons={dataTableButtons}
            filters
            returnTable={(_table) => setTable(_table)}
            returnClickedRow={(_clickedRow) => setTableClickedRow(_clickedRow)}
          />
        )}
        {openFormDialogUser && (
          <ModalFormDialog
            title={t(GlobalMessages.entityUser)}
            open={openFormDialogUser}
            onClose={handleCloseFormDialogUser}
          >
            <UserForm
              currentUser={isEditingAUser ? userToEdit : null}
              isMaintenance
              onSave={handleSaveUserForm}
              isAdmin={decodedToken?.extension_Admin}
            />
          </ModalFormDialog>
        )}

      </Styled.Container>
    </Office>
  );
};

export default MaintenanceUser;
