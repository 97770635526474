import { MaintenanceUserMessages, TranslationMaintenanceUserMessages } from "@languages/interfaces/maintenanceUserMessages";

export const messages: TranslationMaintenanceUserMessages = {
  [MaintenanceUserMessages.columnTenant]: "Tenant",
  [MaintenanceUserMessages.columnFullName]: "Full name",
  [MaintenanceUserMessages.columnEmail]: "Email",
  [MaintenanceUserMessages.columnAdmin]: "Admin",
  [MaintenanceUserMessages.columnActive]: "Status",
  [MaintenanceUserMessages.columnCreatedAt]: "Created at",
  [MaintenanceUserMessages.columnUpdatedAt]: "Last update",
  [MaintenanceUserMessages.dialogDeleteTitle]: "Delete user"
};
