import React from "react";
import { BrowserRouter } from "react-router-dom";
import { ThemeProvider } from "styled-components";

import { IPublicClientApplication } from "@azure/msal-browser";
import { MsalProvider } from "@azure/msal-react";

import { TelluriaProvider } from "@npm-telluria-tecnologia/telluria-ui/dist";
import AppProvider from "@hooks/index";

import i18nInit from "@languages/i18n";

import useTheme from "@styles/useTheme";
import GlobalStyle from "@styles/global";
import Routes from "./routes/Routes";

type RootProps = {
  instance: IPublicClientApplication
}

const Root: React.FC<RootProps> = ({ instance }) => {

  i18nInit();

  const { theme } = useTheme();

  return (
    <MsalProvider instance={instance}>
      <TelluriaProvider components={{ toast: true }}>
        <ThemeProvider theme={theme}>
          <AppProvider>
            <BrowserRouter>
              <GlobalStyle />
              <Routes />
            </BrowserRouter>
          </AppProvider>
        </ThemeProvider>
      </TelluriaProvider>
    </MsalProvider>
  );
};

export default Root;
