import React from "react";

import * as _ from "lodash";

// region Hooks
import { IToken, useAuth } from "@hooks/useAuth";
import { useMsal } from "@azure/msal-react";
// endregion Hooks
// region Molecules
import { Navbar } from "@molecules/index";
// endregion Molecules
// region Styles
import * as Styled from "@pages/Office/styles";
// endregion Styles

const Office: React.FC = ({ children }) => {
  const { instance } = useMsal();
  const activeAccount = instance.getActiveAccount();

  let accessGranted = false;

  if (activeAccount?.idTokenClaims?.["extension_Admin"]) {
    accessGranted = activeAccount?.idTokenClaims?.["extension_Admin"] as boolean;
  }

  return (
    <Styled.Container>
      <Styled.NavbarContainer>
        {accessGranted && (<Navbar />)}
      </Styled.NavbarContainer>
      <Styled.ContentContainer>
        <Styled.Content id="officeContent">
          {children}
        </Styled.Content>
      </Styled.ContentContainer>
    </Styled.Container>
  );
};

export default Office;
