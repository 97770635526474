import {
  TranslationConfirmAccountMessages,
  TranslationDataTableMessages,
  TranslationFilterMessages,
  TranslationGlobalMessages,
  TranslationLoginMessages,
  TranslationMaintenanceUserMessages,
  TranslationUserFormMessages,
  TranslationYupMessages
} from "./interfaces";

interface TranslationData extends TranslationLoginMessages, TranslationConfirmAccountMessages,
  TranslationYupMessages, TranslationUserFormMessages, TranslationDataTableMessages,
  TranslationGlobalMessages, TranslationFilterMessages, TranslationMaintenanceUserMessages {}

export interface LanguageData {translation: TranslationData;}

export type TLanguages = "pt-BR" | "en-US" | "es";

interface ILanguage {
  language: TLanguages;
  description: string;
}

export const languages: ILanguage[] = [
  {
    language: "en-US",
    description: "English"
  },
  {
    language: "pt-BR",
    description: "Português"
  },
  {
    language: "es",
    description: "Español"
  }
];

/**
 * Use this function to get the language data from the language code
 * @param language
 * @param fileName
 * @returns
 */
const getMessages = (language: TLanguages, fileName: string) => {
  const data = require(`./${language}/${fileName}.ts`);

  return data.messages;
};

export function getLanguageData(language: TLanguages) {

  const loginMessages = getMessages(language, "loginMessages") as TranslationLoginMessages;
  const resetPasswordMessages = getMessages(language, "confirmAccountMessages") as TranslationConfirmAccountMessages;
  const yupMessages = getMessages(language, "yupMessages") as TranslationYupMessages;
  const userFormMessages = getMessages(language, "userFormMessages") as TranslationUserFormMessages;
  const dataTableMessages = getMessages(language, "dataTableMessages") as TranslationDataTableMessages;
  const globalMessages = getMessages(language, "globalMessages") as TranslationGlobalMessages;
  const filterMessages = getMessages(language, "filterMessages") as TranslationFilterMessages;
  const maintenanceUserMessages = getMessages(language, "maintenanceUserMessages") as TranslationMaintenanceUserMessages;

  const languageData: LanguageData = {
    translation: {
      ...loginMessages,
      ...resetPasswordMessages,
      ...yupMessages,
      ...userFormMessages,
      ...dataTableMessages,
      ...globalMessages,
      ...filterMessages,
      ...maintenanceUserMessages
    }
  };

  return languageData;
}
