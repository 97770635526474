/* eslint-disable class-methods-use-this,no-prototype-builtins */

import { ECommandResult } from "@store/enums/commandResult.enum";
import React from "react";

class Utils {

  /**
   * Get module link according to the module name
   * @param moduleName Module name
   */
  getModuleLink = (moduleName: string): string => {

    switch (moduleName) {
      case "TOPCON_TECH":
        return "/tech";
      default:
        return "/";
    }
  };

  /**
   * Get command result according to the command result code
   * @param status Command result code
   */
  getToastStatusType = (status: ECommandResult): "success" | "error" | "info" => {
    switch (status) {
      case ECommandResult.SUCCESS:
        return "success";
      case ECommandResult.ERROR:
        return "error";
      default:
        return "info";
    }
  };

  /**
   * Convert string to pascal case
   * @param str String to convert
   */
  toPascalCase = (str: string): string => str.replace(/(\w)(\w*)/g, (_, g1, g2) => g1.toUpperCase() + g2.toLowerCase());

  /**
   * Get backend base url
   */
  getBackendBaseUrl = () => {
    const { protocol, hostname } = window.location;
    const subdomain = hostname.split(".")[0];
    const domain = hostname.substring(hostname.indexOf(".") + 1);

    if (hostname !== "https://dev.topconsuite.app.br") {
      return "https://topcon-identity-api.azurewebsites.net/";
    }

    return `${protocol}//${subdomain}-api.${domain}`;
  };

  /** Prevent form to submit with propagation */
  preventDefaultFormSubmit = (e: React.FormEvent<HTMLFormElement>, handleSubmit : any) => {

    e.preventDefault();
    e.stopPropagation();

    handleSubmit();
  };

  /**
   * Compare the token issuing policy with a specific policy name
   * @param {object} idTokenClaims - Object containing the claims from the parsed token
   * @param {string} policyToCompare - ID/Name of the policy as expressed in the Azure portal
   * @returns {boolean}
   */
  compareIssuingPolicy = (idTokenClaims: any, policyToCompare: any) => {

    const tfpMatches = idTokenClaims.hasOwnProperty("tfp") && idTokenClaims["tfp"].toLowerCase() === policyToCompare.toLowerCase();
    const acrMatches = idTokenClaims.hasOwnProperty("acr") && idTokenClaims["acr"].toLowerCase() === policyToCompare.toLowerCase();

    return tfpMatches || acrMatches;
  };

}

export default new Utils();
