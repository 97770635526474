import { windowWidth } from "@styles/global";
import styled from "styled-components";

const FormConfirmAccountContainer = styled.div`
  background-color: white;
  width: 100%;
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  align-items: center;
  overflow: hidden;
  max-height: 600px;
  flex-direction: column;
  padding: 30px 40px;
  height: auto;
  
  @media (max-width: ${windowWidth.mobile.large}) {
    padding: 20px 20px !important;
  }

  > form {
    width: 100% !important;
    display: flex;
    flex-direction: column;
    align-items: center;

    > * {
      width: 100%;
    }

    > button {
      margin-top: 20px;
      width: 50%;
      background-color: ${(props) => props.theme.colors.primary};

      :hover {
        background-color: ${(props) => props.theme.colors.secondary};
      }
    }
  }

`;

const FormConfirmAccountLogoContainer = styled.div`
  width: 50%;

  @media (max-height: ${windowWidth.tablet}) and (min-width: ${windowWidth.tablet}) {
    width: 45% !important;
  }

  @media (max-height: 700px) {
    padding-bottom: 20px;
  }
`;

const FormConfirmAccountInfoContainer = styled.div`
  margin-top: 20px;
`;

const FormConfirmAccountDialogContainer = styled.div`

  .MuiPaper-root {
    padding: 25px;

    .dialog {

      &__title {
        text-align: center;
        margin-bottom: 25px;

        img {
          width: 100px;
        }
      }

      &__content {
        text-align: center;
        font-size: 16px;
      }

      &__actions {
        display: flex;
        justify-content: center;

        button {
          margin-top: 20px;
          background-color: ${(props) => props.theme.colors.primary};
        }
      }
    }
  }
`;

export { FormConfirmAccountContainer, FormConfirmAccountLogoContainer, FormConfirmAccountInfoContainer, FormConfirmAccountDialogContainer };
