import {
  ConfirmAccountMessages,
  TranslationConfirmAccountMessages
} from "@languages/interfaces/confirmAccountMessages";

// eslint-disable-next-line import/prefer-default-export
export const messages: TranslationConfirmAccountMessages = {
  [ConfirmAccountMessages.confirmAccountLabel]: "Password",
  [ConfirmAccountMessages.confirmAccountRequired]: "Password is required",
  [ConfirmAccountMessages.confirmAccountButton]: "Confirm new password",
  [ConfirmAccountMessages.confirmAccountGoToLoginPageButton]: "Go to login page",
  [ConfirmAccountMessages.confirmAccountFailed]: "Password confirm failed",
  [ConfirmAccountMessages.confirmAccountInfo]: "Please, confirm your account to confirm your password",
  [ConfirmAccountMessages.confirmAccountMessage]: "Account confirmed successfully!<br><br>"
  + "Click the button below to make your first login!<br>"
  + "Welcome to Topcon Suite!"
};
