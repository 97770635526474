import { UserFormMessages, TranslationUserFormMessages } from "@languages/interfaces/userFormMessages";

export const messages: TranslationUserFormMessages = {
  [UserFormMessages.fieldTenant]: "Tenant",
  [UserFormMessages.fieldFullName]: "Full name",
  [UserFormMessages.fieldEmail]: "Email",
  [UserFormMessages.fieldPassword]: "Password",
  [UserFormMessages.fieldAdmin]: "This user is admin",
  [UserFormMessages.fieldNotAdmin]: "This user isn't admin",
  [UserFormMessages.fieldActive]: "This user is active",
  [UserFormMessages.fieldInactive]: "This user is inactive",
  [UserFormMessages.buttonSave]: "Save"
};
