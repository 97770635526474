/* eslint-disable react/require-default-props */

import React from "react";
import { Route, Routes as ReactDOMRoutes } from "react-router-dom";

/** Pages */
import Login from "@pages/Login";
import MaintenanceUser from "@organisms/MaintenanceUser";
import ConfirmAccount from "@pages/ConfirmAccount";
import PrivateRoute from "src/routes/PrivateRoute";

const Routes: React.FC = () => (
  <ReactDOMRoutes>
    <Route path="/" element={<Login />} />
    <Route path="/confirm-account" element={<ConfirmAccount />} />
    <Route path="/maintenanceuser" element={<PrivateRoute component={<MaintenanceUser />} onlyAdmin />} />
  </ReactDOMRoutes>
);

export default Routes;
