/* eslint-disable no-restricted-globals */

import React, { createContext, useCallback, useContext, useMemo, useState } from "react";
import jwt from "jwt-decode";
import { AxiosResponse } from "axios";

// region External Libraries
import { AccountInfo, InteractionRequiredAuthError } from "@azure/msal-browser";
import { useMsal } from "@azure/msal-react";
// endregion External Libraries
// region Languages
import useTranslation from "@languages/useTranslation";
// endregion Languages
// region Pages
// endregion Pages
// region Store
import api from "@store/services/rest";
import { User } from "@store/interfaces/user.interface";
import { ECommandResult } from "@store/enums/commandResult.enum";
// endregion Store

// region Interfaces
import { loginRequest } from "src/authConfig";
// interface SignInCredentials {
//   email: string;
//   password: string;
// }

interface ConfirmAccountCredentials {
  idUser: string;
  newPassword: string;
  confirmationToken: string;
}

export interface IToken {
  ver: string;
  iss: string;
  sub: string;
  aud: string;
  exp: number;
  nonce: string;
  iat: number;
  name: string;
  emails: string[];
  tfp: string;
  nbf: number;
  // modules: { [key: string]: string[] };
  // claims: { [key: string]: string[] };
  // roles: { [key: string]: string[] };
  extension_Admin: boolean;
  extension_UserPermissions: string;
}

// interface AuthState {
//   user: User;
// }

interface AuthContextData {
  // user: User;
  // validateToken: (token: string) => boolean;
  // getTokenData: (token: string) => any;
  // signIn(credentials: SignInCredentials): Promise<any>;
  signOut(): void;
  confirmAccount(credentials: ConfirmAccountCredentials): Promise<AxiosResponse>;
  getToken: () => Promise<string>;
  getDecodedToken: () => Promise<IToken | null>;
  getActiveAccount: () => AccountInfo | null;
}
// endregion Interfaces

const AuthContext = createContext<AuthContextData>({} as AuthContextData);
const AuthProvider: React.FC = ({ children }) => {
  const { instance, accounts } = useMsal();

  const { i18n } = useTranslation();
  // const [data, setData] = useState<AuthState>(() => {

  //   const token = localStorage.getItem("@Topcon:token");

  //   if (token) {

  //     api.defaults.headers.common["Authorization"] = `Bearer ${token}`;
  //     api.defaults.headers.common["Accept-Language"] = i18n.language;

  //     const tokenData = jwt(token);

  //     return { user: tokenData as User };
  //   }

  //   return {} as AuthState;
  // });

  // /**
  //  * Log in and store data access
  //  * @param { email, password }
  //  */
  // const signIn = useCallback(async ({ email, password }) => {

  //   const response = await api.post("/auth/login", { email, password });
  //   const responseData = response.data;

  //   if (responseData.status === ECommandResult.SUCCESS) {

  //     const user = responseData.result;

  //     // Set token data on local storage
  //     localStorage.setItem("@Topcon:token", user.token);

  //     setData({ user });
  //   }

  //   return responseData;

  // }, []);

  /**
   * Log out and clear store data access
   */
  const signOut = useCallback(() => {

    localStorage.removeItem("@Topcon:token");
    // setData({} as AuthState);

  }, []);

  // /**
  //  * Get token decrypted data
  //  * @param token
  //  */
  // const getTokenData = useCallback((token: string) => jwt(token) as any, []);

  // /**
  //  * Verify if token is valid
  //  * @param token
  //  */
  // const validateToken = useCallback((token: string) => {

  //   if (!token) return false;

  //   try {
  //     const decoded = getTokenData(token);

  //     if (!decoded.exp) return false;

  //     return Date.now() < decoded.exp * 1000;
  //   } catch (e) {
  //     return false;
  //   }
  // }, [getTokenData]);

  const getMsalData = useCallback(async () => {
    const request = {
      scopes: loginRequest.scopes,
      account: accounts[0]
    };

    try {
      const response = await instance.acquireTokenSilent(request);

      return response;
    } catch (error) {
      if (error instanceof InteractionRequiredAuthError) {
        // need be implemented
      }
    }

    return null;
  }, [instance, accounts]);

  /**
   * Get token from Msal
   */
  const getToken = useCallback(async () => {
    const msalResponse = await getMsalData();

    if (msalResponse) {
      return msalResponse.idToken;
    }

    return "";
  }, [getMsalData]);

  /**
   * Get token decrypted from Msal
   */
  const getDecodedToken = useCallback(async () => {
    const msalResponse = await getMsalData();

    if (msalResponse) {
      return msalResponse.idTokenClaims as IToken;
    }

    return null;

  }, [getMsalData]);

  /**
   * Get active account from Msal
   */
  const getActiveAccount = useCallback(() => instance.getActiveAccount(), [instance]);

  /**
   * Confirm account
   * For reset and confirm account
   * @param idUser User to change / confirm
   * @param password New password
   * @param confirmationToken Generated token to confirm account
   */
  const confirmAccount = useCallback(async ({ idUser, newPassword, confirmationToken }) => {

    const queryParams = `?userId=${idUser}&code=${confirmationToken}`;

    return await api.post(`/user/confirm-account${queryParams}`, { password: newPassword });

  }, []);

  // Return the context data
  const provider = useMemo(() => ({
    signOut,
    confirmAccount,
    getToken,
    getDecodedToken,
    getActiveAccount
  }), []);

  return (
    <AuthContext.Provider value={provider}>
      {children}
    </AuthContext.Provider>
  );
};

function useAuth(): AuthContextData {
  return useContext(AuthContext);
}

export { AuthProvider, useAuth };
