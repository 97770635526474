import React from "react";
import { Navigate } from "react-router-dom";

// region Hooks
import { AuthenticatedTemplate, UnauthenticatedTemplate, useMsal } from "@azure/msal-react";
import { on } from "events";
// endregion Hooks

// region Interfaces
interface RouteProps<T = any> {
  component: React.ReactElement<T>;
  onlyAdmin?: boolean;
}
// endregion Interfaces

const PrivateRoute: React.FC<RouteProps> = ({
  component: Component,
  onlyAdmin = false
}) => {

  const { instance } = useMsal();
  const activeAccount = instance.getActiveAccount();

  return (
    <>
      <AuthenticatedTemplate>
        {activeAccount ? (
          (onlyAdmin && !activeAccount.idTokenClaims?.["extension_Admin"]) ? (
            <Navigate to="/" />
          ) : (
            Component
          )
        ) : null}
      </AuthenticatedTemplate>
      <UnauthenticatedTemplate>
        <Navigate to="/" />
      </UnauthenticatedTemplate>
    </>
  );
};

PrivateRoute.defaultProps = {
  onlyAdmin: false
};

export default PrivateRoute;
