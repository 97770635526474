export enum MaintenanceUserMessages {
  columnTenant = "maintenanceUser.columns.columnTenant",
  columnFullName = "maintenanceUser.columns.columnFullName",
  columnEmail = "maintenanceUser.columns.columnEmail",
  columnAdmin = "maintenanceUser.columns.columnAdmin",
  columnActive = "maintenanceUser.columns.columnActive",
  columnCreatedAt = "maintenanceUser.columns.columnCreatedAt",
  columnUpdatedAt = "maintenanceUser.columns.columnUpdatedAt",
  dialogDeleteTitle = "maintenanceUser.dialog.delete.title"
}

export interface TranslationMaintenanceUserMessages {
  [MaintenanceUserMessages.columnTenant]: string;
  [MaintenanceUserMessages.columnFullName]: string;
  [MaintenanceUserMessages.columnEmail]: string;
  [MaintenanceUserMessages.columnAdmin]: string;
  [MaintenanceUserMessages.columnActive]: string;
  [MaintenanceUserMessages.columnCreatedAt]: string;
  [MaintenanceUserMessages.columnUpdatedAt]: string;
  [MaintenanceUserMessages.dialogDeleteTitle]: string;
}
