import React from "react";
import FormConfirmAccount from "src/molecules/FormConfirmAccount";
import * as Styled from "./styles";

const ConfirmAccount: React.FC = () => (
  <Styled.Container>
    <Styled.FormContainer>
      <FormConfirmAccount />
    </Styled.FormContainer>
  </Styled.Container>
);

export default ConfirmAccount;
